/* Base16 Atelier Dune Light - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/dune) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */

/* Atelier-Dune Comment */
.hljs-comment,
.hljs-quote {
  color: #7d7a68;
}

/* Atelier-Dune Red */
.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-tag,
.hljs-name,
.hljs-regexp,
.hljs-link,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #d73737;
}

/* Atelier-Dune Orange */
.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #b65611;
}

/* Atelier-Dune Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #60ac39;
}

/* Atelier-Dune Blue */
.hljs-title,
.hljs-section {
  color: #6684e1;
}

/* Atelier-Dune Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #b854d4;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #fefbec;
  color: #6e6b5e;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
